<template>
  <v-container fluid class="custom-bthrust-dashboard white pt-0">
    <v-container fluid style="min-height: 80vh" class="pa-3">
      <v-layout>
        <div class="mr-5 text-h6">
          <v-icon size="14" class="mb-1" color="orange">mdi-circle</v-icon>
          In-Timeline
        </div>
        <div class="mr-5 text-h6">
          <v-icon size="14" class="mb-1" color="blue darken-4"
            >mdi-circle</v-icon
          >
          Over-Timeline
        </div>
        <div class="mr-5 text-h6">
          <v-icon size="14" class="mb-1" color="cyan">mdi-circle</v-icon>
          Within-Budget
        </div>
        <div class="mr-5 text-h6">
          <v-icon size="14" class="mb-1" color="red">mdi-circle</v-icon>
          Out of Budget
        </div>
      </v-layout>
      <div
        class="grey lighten-4 mt-4 pa-4 rounded-lg"
        style="border: 1px solid #cdc6c6 !important"
      >
        <v-layout class="align-items-center">
          <div class="text-h5 fw-500">Projects</div>
          <v-spacer></v-spacer>
          <v-menu v-if = "false" offset-y>
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                class="grey lighten-2 rounded-md"
                text
                tile
                v-bind="attrs"
                v-on="on"
              >
                <span class="fs-14 fw-500 text-transform-initial">
                  {{ filtered_project }}
                </span>
                <v-icon size="28" right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link @click="filtered_project = 'All Project'">
                <v-list-item-title> All Project </v-list-item-title>
              </v-list-item>
              <v-divider class="my-0"></v-divider>
              <v-list-item link @click="filtered_project = 'One by One'">
                <v-list-item-title> One by One </v-list-item-title>
              </v-list-item>
              <v-divider class="my-0"></v-divider>
              <v-list-item link @click="filtered_project = 'Monthly'">
                <v-list-item-title> Monthly </v-list-item-title>
              </v-list-item>
              <v-divider class="my-0"></v-divider>
              <v-list-item link @click="filtered_project = 'Yearly'">
                <v-list-item-title> Yearly </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-layout>
        <template >
        <v-row >
          <template v-for="(project, index) in projectCountList"
          >
          <v-col  :key = "index"
            v-if = "project.value != 'all'"
          >   
                <DCard 
                  :title="project.text"
                  :title-color="project.color"
                  :total-count= "project.project_status_count"
                  :child-count="true"
                ></DCard>
          </v-col>
        </template>
        </v-row>
      </template>
      </div>


      <div
        class="grey lighten-4 mt-4 pa-4 rounded-lg"
        style="border: 1px solid #cdc6c6 !important"
      >
        <v-layout class="align-items-center">
          <div class="text-h5 fw-500">Tasks</div>
          <v-spacer></v-spacer>
          <v-menu v-if = "false" offset-y>
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                class="grey lighten-2 rounded-md"
                text
                tile
                v-bind="attrs"
                v-on="on"
              >
                <span class="fs-14 fw-500 text-transform-initial">
                  {{ filtered_task }}
                </span>
                <v-icon size="28" right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link @click="filtered_task = 'All Task'">
                <v-list-item-title> All Project </v-list-item-title>
              </v-list-item>
              <v-divider class="my-0"></v-divider>
              <v-list-item link @click="filtered_task = 'One by One'">
                <v-list-item-title> One by One </v-list-item-title>
              </v-list-item>
              <v-divider class="my-0"></v-divider>
              <v-list-item link @click="filtered_task = 'Monthly'">
                <v-list-item-title> Monthly </v-list-item-title>
              </v-list-item>
              <v-divider class="my-0"></v-divider>
              <v-list-item link @click="filtered_task = 'Yearly'">
                <v-list-item-title> Yearly </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-layout>

        
        <v-row>
          <template v-for="(task, index) in taskCountList"
          >
          <v-col  :key = "index"
            v-if = "task.value != 'all'"
          >   
                <DCard 
                  :title="task.text"
                  :title-color="task.color"
                  :total-count= "task.task_status_count"
                  :child-count="false"
                ></DCard>
            
          </v-col>
        </template>
          
        </v-row>
      </div>
      <div
        class="grey lighten-4 mt-4 pa-4 rounded-lg"
        style="border: 1px solid #cdc6c6 !important"
      >
        <v-layout class="align-items-center">
          <div class="text-h5 fw-500">Tickets</div>
          <v-spacer></v-spacer>
          <v-menu v-if = "false" offset-y>
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                class="grey lighten-2 rounded-md"
                text
                tile
                v-bind="attrs"
                v-on="on"
              >
                <span class="fs-14 fw-500 text-transform-initial">
                  {{ filtered_ticket }}
                </span>
                <v-icon size="28" right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link @click="filtered_ticket = 'All Ticket'">
                <v-list-item-title> All Project </v-list-item-title>
              </v-list-item>
              <v-divider class="my-0"></v-divider>
              <v-list-item link @click="filtered_ticket = 'One by One'">
                <v-list-item-title> One by One </v-list-item-title>
              </v-list-item>
              <v-divider class="my-0"></v-divider>
              <v-list-item link @click="filtered_ticket = 'Monthly'">
                <v-list-item-title> Monthly </v-list-item-title>
              </v-list-item>
              <v-divider class="my-0"></v-divider>
              <v-list-item link @click="filtered_ticket = 'Yearly'">
                <v-list-item-title> Yearly </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-layout>
        

        <v-row>
          <template v-for="(ticket, index) in ticketCountList"
          >
          <v-col  :key = "index"
            v-if = "ticket.value != 'all'"
          >   
                <DCard 
                  :title="ticket.text"
                  :title-color="ticket.color"
                  :total-count= "ticket.ticket_status_count"
                  :child-count="false"
                ></DCard>
            
          </v-col>
        </template>
          
        </v-row>
      </div>
      <div
        class="blue lighten-5 mt-4 pa-2 rounded-lg"
        style="border: 1px solid #cdc6c6 !important"
      >
        <v-layout class="align-items-center px-3">
          <div class="text-h5 fw-500">Project Budget</div>
          <v-spacer></v-spacer>
          <v-menu v-if="false" offset-y>
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                class="grey lighten-2 rounded-md"
                text
                tile
                v-bind="attrs"
                v-on="on"
              >
                <span class="fs-14 fw-500 text-transform-initial">
                  {{ filtered_project_budget }}
                </span>
                <v-icon size="28" right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                link
                @click="filtered_project_budget = 'All Project'"
              >
                <v-list-item-title> All Project </v-list-item-title>
              </v-list-item>
              <v-divider class="my-0"></v-divider>
              <v-list-item link @click="filtered_project_budget = 'One by One'">
                <v-list-item-title> One by One </v-list-item-title>
              </v-list-item>
              <v-divider class="my-0"></v-divider>
              <v-list-item link @click="filtered_project_budget = 'Monthly'">
                <v-list-item-title> Monthly </v-list-item-title>
              </v-list-item>
              <v-divider class="my-0"></v-divider>
              <v-list-item link @click="filtered_project_budget = 'Yearly'">
                <v-list-item-title> Yearly </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-layout>
        <ProjectChart
          v-if="projectBudgetSeries && projectBudgetCategories.length>0"
          :series-data="projectBudgetSeries"
          :categories-data="projectBudgetCategories"
        ></ProjectChart>
      </div>

      <div v-if = "false"
        class="blue lighten-5 mt-4 pa-4 rounded-lg"
        style="border: 1px solid #cdc6c6 !important"
      >
        <Calendar></Calendar>
      </div>
      <div
        class="mt-4 pa-4 rounded-lg"
        style="border: 1px solid #cdc6c6 !important"
        v-if = "false"
      >
        <v-layout class="align-items-center">
          <div class="text-h5 fw-500">OVERDUE Jobs</div>
        </v-layout>
        <v-col
          v-for="n in 5"
          :key="n"
          class="rounded-lg mt-4"
          style="border: 1px solid #cdc6c6 !important"
        >
          <v-row>
            <v-col class="py-1" style="max-width: 120px">
              <div>
                <v-chip label small outlined color="orange" class="mb-1">
                  VISTI-14
                </v-chip>
              </div>
              <div>
                <v-chip label outlined small color="blue" class="mb-1">
                  RETIC-04
                </v-chip>
              </div>
            </v-col>
            <v-col class="py-1">
              <div class="mb-1">
                <span class="fw-500">Job Title :</span> Adhoc Testing
              </div>
              <div>
                <span class="fw-500"> Address :</span>
                Block 1004 Eunos <br />
                Avenue 8 #01-29, Singapore<br />
                409499
              </div>
            </v-col>
            <v-col class="py-1">
              <div class="mb-1">
                <v-icon small>mdi-account</v-icon> teoh huiwen
              </div>
              <div class="mb-1">
                <v-icon small>mdi-bag-checked</v-icon> Teoh Hui
              </div>
              <div class="mb-1">
                <v-icon small>mdi-email</v-icon> akeem96@lubowitz.net
              </div>
              <div class="mb-1">
                <v-icon small>mdi-phone</v-icon> +65-1124 7836 58
              </div>
            </v-col>
            <v-col class="py-1">
              <div class="mb-1">03/03/2022</div>
              <div class="mb-1">00:00 - 23:59</div>
              <div class="mb-1">
                <v-chip label small color="orange white--text">
                  Complated In
                </v-chip>
              </div>
            </v-col>
            <v-col class="py-1">
              <div class="mb-1">
                <v-chip label small color="green white--text">
                  Complated
                </v-chip>
              </div>
              <div class="mb-1">
                <v-chip label outlined small color="orange white--text">
                  Low
                </v-chip>
              </div>
              <div class="mb-1">
                <v-chip label outlined small color="blue darken-4 white--text">
                  Recurring Job
                </v-chip>
              </div>
            </v-col>
            <v-col class="py-1">
              <div class="mb-1">
                <span class="fw-500"> Assigned To :</span> John Doe
              </div>
              <div class="mb-1">
                <v-chip label small color="red white--text" class="mb-1">
                  <v-icon left small>mdi-delete</v-icon>
                  Delete
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </div>
    </v-container>


    <!-- Previous Code -->
    <v-row >
      <v-col v-if = "false" md="12">
        <v-layout class="dashboard-top-button">
          <v-flex>
            <router-link
              :to="getDefaultRoute('customer.create')"
              class="btn btn-danger btn-shadow-hover font-weight-bolder w-100 font-size-18"
              >Create Customer</router-link
            >
          </v-flex>
          <v-flex class="mx-4">
            <router-link
              to=""
              @click.native="createTransaction('quotation')"
              class="btn btn-danger btn-shadow-hover font-weight-bolder w-100 font-size-18"
              >Create Quotation</router-link
            >
          </v-flex>
          <v-flex>
            <router-link
              to=""
              @click.native="createTransaction('job')"
              class="btn btn-danger btn-shadow-hover font-weight-bolder w-100 font-size-18"
              >Create Job</router-link
            >
          </v-flex>
          <v-flex class="mx-4">
            <router-link
              to=""
              @click.native="createTransaction('invoice')"
              class="btn btn-danger btn-shadow-hover font-weight-bolder w-100 font-size-18"
              >Create Invoice</router-link
            >
          </v-flex>
          <v-flex>
            <router-link
              :to="getDefaultRoute('engineer.create')"
              class="btn btn-danger btn-shadow-hover font-weight-bolder w-100 font-size-18"
              >Create Engineer</router-link
            >
          </v-flex>
          <v-flex class="ml-4">
            <router-link
              :to="getDefaultRoute('profile.setting')"
              class="btn btn-danger btn-shadow-hover font-weight-bolder w-100 font-size-18"
              >Settings</router-link
            >
          </v-flex>
        </v-layout>
      </v-col>
      <v-col v-if = "false" md="4">
        <!--begin::Mixed Widget 1-->
        <div class="card card-custom bg-gray-100 card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 bg-danger py-5 pb-2">
            <h3 class="card-title font-weight-bolder text-white">
              Jobs Status
            </h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body p-0 position-relative overflow-hidden">
            <!--begin::Chart-->
            <div
              id="kt_mixed_widget_1_chart"
              class="card-rounded-bottom bg-danger"
              style="height: 100px"
            ></div>
            <div class="card-spacer smallbxc_row">
              <!--begin::Row-->
              <div class="row m-0">
                <div class="col bg-light-warning ttbox rounded-xl mr-7">
                  <span
                    class="svg-icon svg-icon-3x svg-icon-warning d-block my-2"
                  >
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/ticket.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="warning"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ jobCount.total_jobs }}
                    </template>
                  </div>
                  <router-link
                    :to="
                      getDefaultRoute('job', {
                        query: {
                          status: 'all',
                        },
                      })
                    "
                    class="text-warning font-weight-bold font-size-h6"
                    >Total Jobs</router-link
                  >
                </div>
                <div class="col bg-light-primary ttbox rounded-xl">
                  <span
                    class="svg-icon svg-icon-3x svg-icon-primary d-block my-2"
                  >
                    <inline-svg
                      :src="$assetURL('media/custom-svg/tickets.svg')"
                    />
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ jobCount.total_open_jobs }}
                    </template>
                  </div>
                  <router-link
                    :to="
                      getDefaultRoute('job', {
                        query: {
                          status: 'open',
                        },
                      })
                    "
                    class="text-primary font-weight-bold font-size-h6"
                    >Total Open Jobs</router-link
                  >
                </div>
              </div>
              <!--end::Row-->
              <!--begin::Row-->
              <div class="row">
                <div class="col bg-light-info ttbox rounded-xl mr-7">
                  <span class="svg-icon svg-icon-3x svg-icon-info d-block">
                    <inline-svg
                      :src="$assetURL('media/custom-svg/close-t.svg')"
                    />
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="info"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ jobCount.total_closed_jobs }}
                    </template>
                  </div>
                  <router-link
                    :to="
                      getDefaultRoute('job', {
                        query: {
                          status: 'closed',
                        },
                      })
                    "
                    class="text-info font-weight-bold font-size-h6"
                    >Total Closed Jobs</router-link
                  >
                </div>
                <div class="col bg-light-success ttbox rounded-xl">
                  <span
                    class="svg-icon svg-icon-3x svg-icon-success d-block my-2"
                  >
                    <inline-svg
                      :src="$assetURL('media/custom-svg/delete.svg')"
                    />
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="success"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ jobCount.total_cancelled_jobs }}
                    </template>
                  </div>
                  <router-link
                    :to="
                      getDefaultRoute('job', {
                        query: {
                          status: 'cancel',
                        },
                      })
                    "
                    class="text-success font-weight-bold font-size-h6"
                    >Total Cancel Jobs</router-link
                  >
                </div>
              </div>
              <!--end::Row-->

              <!--begin::Row-->
              <div class="row m-0">
                <div class="col bg-light-danger ttbox rounded-xl mr-7">
                  <span class="svg-icon svg-icon-3x svg-icon-danger d-block">
                    <inline-svg :src="$assetURL('media/custom-svg/tt1.svg')" />
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="red"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ visitCount.total_overdue_visits }}
                    </template>
                  </div>
                  <a
                    href="javascript:void(0)"
                    class="text-danger cursor-default font-weight-bold font-size-h6 mt-2"
                    >Total Overdue Visits</a
                  >
                </div>
                <div class="col bg-light-warning ttbox rounded-xl">
                  <span class="svg-icon svg-icon-3x svg-icon-success d-block">
                    <inline-svg :src="$assetURL('media/custom-svg/bug.svg')" />
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="success"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ bugCount }}
                    </template>
                  </div>
                  <router-link
                    :to="getDefaultRoute('bug.report')"
                    class="text-success font-weight-bold font-size-h6"
                    >Bug Reports</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Mixed Widget 1-->
      </v-col>
      <v-col v-if = "false" md="4">
        <div class="card dash_card card-custom gutter-b">
          <div class="card-body p-0">
            <div class="cardtopbar">
              <div
                class="float-right custom-menu-select py-1 btn btn-success btn-shadow-hover"
              >
                <v-select
                  :items="durationList"
                  v-model="customerDuration"
                  hide-details
                  :disabled="customerCountLoading"
                  item-color="cyan"
                  color="cyan"
                  class="pt-0 mt-0"
                  v-on:change="getCustomerCount"
                >
                </v-select>
              </div>
              <h4>CUSTOMERS</h4>
              <span class="text-black mt-3 font-weight-400 font-size-sm"
                >You have
                <template v-if="customerCountLoading">
                  <v-progress-circular
                    indeterminate
                    size="14"
                    width="2"
                  ></v-progress-circular>
                </template>
                <template v-else>
                  {{ customerCount.count }}
                </template>
                new customers {{ lodash.lowerCase(customerDuration) }}</span
              >
            </div>
            <div
              id="kt_stats_widget_11_chart"
              class="card-rounded-bottom mid_part"
              data-color="danger"
            >
              <div
                class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
              >
                <span
                  class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                >
                  <span class="symbol-label">
                    <span class="svg-icon svg-icon-xl svg-icon-success">
                      <inline-svg
                        :src="$assetURL('media/custom-svg/men.svg')"
                      />
                    </span>
                  </span>
                </span>
                <div class="d-flex flex-column text-right">
                  <span class="qnt_val font-weight-bolder text-success">
                    <template v-if="customerCountLoading">
                      <v-progress-circular
                        indeterminate
                        color="success"
                      ></v-progress-circular>
                    </template>
                    <template v-else>{{ customerCount.percentage }} %</template>
                  </span>
                  <span class="text-muted font-weight-bold mt-2"
                    >more than
                    {{
                      lodash.replace(
                        lodash.lowerCase(customerDuration),
                        "this",
                        "last"
                      )
                    }}</span
                  >
                </div>
              </div>
              <div class="active_mem">
                <ul>
                  <li>
                    All Customers
                    <span class="bg-light-warning">
                      <template v-if="customerCountLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="warning"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ customerCount.all_customers }}
                      </template>
                    </span>
                  </li>
                  <li>
                    Active Customers
                    <span class="bg-light-success"
                      ><template v-if="customerCountLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="success"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ customerCount.active_customers }}
                      </template></span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card dash_card card-custom gutter-b">
          <div class="card-body p-0">
            <div class="cardtopbar">
              <div
                class="float-right custom-menu-select py-1 btn btn-warning btn-shadow-hover"
              >
                <v-select
                  :items="durationList"
                  v-model="jobDuration"
                  hide-details
                  :disabled="jobCountLoading"
                  item-color="cyan"
                  color="cyan"
                  class="pt-0 mt-0"
                  v-on:change="getJobsCount"
                >
                </v-select>
              </div>
              <h4>JOBS</h4>
              <span class="text-black mt-3 font-weight-400 font-size-sm"
                >You have
                <template v-if="jobCountLoading">
                  <v-progress-circular
                    indeterminate
                    size="14"
                    width="2"
                  ></v-progress-circular>
                </template>
                <template v-else>
                  {{ jobsCount.count }}
                </template>
                new job scheduled for {{ lodash.lowerCase(jobDuration) }}</span
              >
            </div>
            <div
              id="kt_stats_widget_11_chart"
              class="card-rounded-bottom mid_part"
              data-color="danger"
            >
              <div
                class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
              >
                <span
                  class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                >
                  <span class="symbol-label">
                    <span class="svg-icon svg-icon-xl svg-icon-warning">
                      <inline-svg
                        :src="$assetURL('media/custom-svg/ticket.svg')"
                      />
                    </span>
                  </span>
                </span>
                <div class="d-flex flex-column text-right">
                  <span class="qnt_val font-weight-bolder text-warning">
                    <template v-if="jobCountLoading">
                      <v-progress-circular
                        indeterminate
                        color="warning"
                      ></v-progress-circular>
                    </template>
                    <template v-else>{{ jobsCount.percentage }} %</template>
                  </span>
                  <span class="text-muted font-weight-bold mt-2"
                    >more than
                    {{
                      lodash.replace(
                        lodash.lowerCase(jobDuration),
                        "this",
                        "last"
                      )
                    }}</span
                  >
                </div>
              </div>
              <div class="active_mem">
                <ul>
                  <li>
                    All Jobs
                    <span class="bg-light-warning">
                      <template v-if="jobCountLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="warning"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ jobsCount.all_jobs }}
                      </template>
                    </span>
                  </li>
                  <li>
                    Recurring Jobs
                    <span class="bg-light-success"
                      ><template v-if="jobCountLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="success"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ jobsCount.recurring_jobs }}
                      </template></span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col v-if = "false" md="4">
        <div class="card card-custom gutter-b dash_card">
          <div class="card-body p-0">
            <div class="cardtopbar">
              <div
                class="float-right custom-menu-select py-1 btn btn-primary btn-shadow-hover"
              >
                <v-select
                  :items="durationList"
                  v-model="quotationDuration"
                  hide-details
                  :disabled="quotationCountLoading"
                  item-color="cyan"
                  class="pt-0 mt-0"
                  v-on:change="getQuotationCount"
                >
                </v-select>
              </div>
              <h4>QUOTATIONS</h4>
              <span class="text-black mt-3 font-weight-400 font-size-sm"
                >You have
                <template v-if="quotationCountLoading">
                  <v-progress-circular
                    indeterminate
                    size="14"
                    width="2"
                  ></v-progress-circular>
                </template>
                <template v-else>
                  {{ quotationCount.count }}
                </template>
                new quotation {{ lodash.lowerCase(quotationDuration) }}</span
              >
            </div>
            <div
              id="kt_stats_widget_11_chart"
              class="card-rounded-bottom mid_part"
              data-color="danger"
            >
              <div
                class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
              >
                <span
                  class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                >
                  <span class="symbol-label">
                    <span class="svg-icon svg-icon-xl svg-icon-primary">
                      <inline-svg
                        :src="$assetURL('media/custom-svg/clipboard.svg')"
                      />
                    </span>
                  </span>
                </span>
                <div class="d-flex flex-column text-right">
                  <span class="qnt_val font-weight-bolder text-primary">
                    <template v-if="quotationCountLoading">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </template>
                    <template v-else
                      >{{ quotationCount.percentage }} %</template
                    >
                  </span>
                  <span class="text-muted font-weight-bold mt-2"
                    >more than
                    {{
                      lodash.replace(
                        lodash.lowerCase(quotationDuration),
                        "this",
                        "last"
                      )
                    }}</span
                  >
                </div>
              </div>
              <div class="active_mem">
                <ul>
                  <li>
                    Average Revenue
                    <span class="bg-light-success"
                      ><template v-if="quotationCountLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="success"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ quotationCount.average_revenue }}
                      </template></span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-custom gutter-b dash_card">
          <div class="card-body p-0">
            <div class="cardtopbar">
              <h4>VISITS DUE TIMES</h4>
            </div>
            <div
              id="kt_stats_widget_11_chart"
              class="card-rounded-bottom mid_part"
              data-color="danger"
            >
              <div
                class="dash_bottom d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
              >
                <span
                  class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                >
                  <span class="symbol-label">
                    <span class="svg-icon svg-icon-xl svg-icon-danger">
                      <inline-svg
                        :src="$assetURL('media/custom-svg/clock.svg')"
                      />
                    </span>
                  </span>
                </span>
                <div class="d-flex flex-column text-right v_list">
                  <ul>
                    <li>
                      <span class="svg-icon svg-icon-xl svg-icon-danger">
                        <inline-svg
                          :src="$assetURL('media/custom-svg/right.svg')"
                        />
                      </span>
                      <strong>In-Progress</strong>
                      <div class="vdnum" v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="red"
                        ></v-progress-circular>
                      </div>
                      <span v-else>
                        {{ visitCount.total_inprogress_visits }}
                      </span>
                    </li>
                    <li>
                      <span class="svg-icon svg-icon-xl svg-icon-danger">
                        <inline-svg
                          :src="$assetURL('media/custom-svg/right.svg')"
                        />
                      </span>
                      <strong>Today</strong>
                      <div class="vdnum" v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="red"
                        ></v-progress-circular>
                      </div>
                      <span v-else>
                        {{ visitCount.total_today_visits }}
                      </span>
                    </li>
                    <li>
                      <span class="svg-icon svg-icon-xl svg-icon-danger">
                        <inline-svg
                          :src="$assetURL('media/custom-svg/right.svg')"
                        />
                      </span>
                      <strong>Tomorrow</strong>
                      <div class="vdnum" v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="red"
                        ></v-progress-circular>
                      </div>
                      <span v-else>
                        {{ visitCount.total_tomorrow_visits }}
                      </span>
                    </li>
                    <li>
                      <span class="svg-icon svg-icon-xl svg-icon-danger">
                        <inline-svg
                          :src="$assetURL('media/custom-svg/right.svg')"
                        />
                      </span>
                      <strong>This week</strong>
                      <div class="vdnum" v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="red"
                        ></v-progress-circular>
                      </div>
                      <span v-else>
                        {{ visitCount.total_this_week_visits }}
                      </span>
                    </li>
                    <li>
                      <span class="svg-icon svg-icon-xl svg-icon-danger">
                        <inline-svg
                          :src="$assetURL('media/custom-svg/right.svg')"
                        />
                      </span>
                      <strong>Upcomming</strong>
                      <div class="vdnum" v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="red"
                        ></v-progress-circular>
                      </div>
                      <span v-else>
                        {{ visitCount.total_upcomming_visits }}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col md="6" sm="12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title justify-space-between width-100">
              <h3 class="card-label">PRIORITY-WISE JOBS</h3>
              <div
                class="text-right custom-menu-select py-1 btn btn-primary btn-shadow-hover"
              >
                <v-select
                  :items="durationList"
                  v-model="priorityDuration"
                  hide-details
                  :disabled="priorityCountLoading"
                  item-color="cyan"
                  class="pt-0 mt-0"
                  v-on:change="getPriorityGraph"
                >
                </v-select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <template v-if="priorityCountLoading">
              <div class="min-height-400px d-flex align-center">
                <v-progress-linear
                  color="primary"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </div>
            </template>
            <template v-else>
              <apexchart
                height="455"
                type="area"
                :options="priorityChartoptions"
                :series="prioritySeries"
              ></apexchart>
            </template>
          </div>
        </div>
      </v-col>
      <v-col md="6" sm="12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title justify-space-between width-100">
              <h3 class="card-label">ISSUE-WISE JOBS</h3>
              <div
                class="text-right custom-menu-select py-1 btn btn-primary btn-shadow-hover"
              >
                <v-select
                  :items="durationList"
                  v-model="issueDuration"
                  hide-details
                  :disabled="issueCountLoading"
                  item-color="cyan"
                  class="pt-0 mt-0"
                  v-on:change="getIssueGraph"
                >
                </v-select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <template v-if="issueCountLoading">
              <div class="min-height-400px d-flex align-center">
                <v-progress-linear
                  color="primary"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </div>
            </template>
            <template v-else>
              <apexchart
                height="468"
                type="pie"
                :options="issueChartoptions"
                :series="issueSeries"
              ></apexchart>
            </template>
          </div>
        </div>
      </v-col>
      <v-col md="12" sm="12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title justify-space-between width-100">
              <h3 class="card-label">ENGINEER WORKING STATS</h3>
              <div
                class="text-right custom-menu-select py-1 btn btn-primary btn-shadow-hover"
              >
                <v-select
                  :items="durationList"
                  v-model="issueDuration"
                  hide-details
                  :disabled="issueCountLoading"
                  item-color="cyan"
                  class="pt-0 mt-0"
                  v-on:change="getIssueGraph"
                >
                </v-select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <template v-if="issueCountLoading">
              <div class="min-height-400px d-flex align-center">
                <v-progress-linear
                  color="primary"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </div>
            </template>
            <template v-else>
                <apexchart
                    type="line"
                    height="350"
                    :options="linechartOptions"
                    :series="lineseries"
                  ></apexchart>
            </template>
          </div>
        </div>
      </v-col>
      
    </v-row>
    <template>
      <template v-if="customerDialog">
        <CustomerDialog
          :customerDialog.sync="customerDialog"
          v-on:closeDialog="closeDialog"
          v-on:resetAll="resetAll"
          v-on:selectCustomer="selectCustomer"
          v-on:selectCustomerPerson="selectCustomerPerson"
          v-on:selectCustomerProperty="selectCustomerProperty"
          v-on:selectCustomerBilling="selectCustomerBilling"
        ></CustomerDialog>
      </template>
      <template v-if="customerPersonDialog">
        <CustomerPersonDialog
          :customerPersonDialog.sync="customerPersonDialog"
          :customer.sync="customer"
          v-on:closeDialog="closeDialog"
          v-on:resetAll="resetAll"
          v-on:selectCustomerPerson="selectCustomerPerson"
        ></CustomerPersonDialog>
      </template>
      <template v-if="customerPropertyDialog">
        <CustomerPropertyDialog
          :customerPropertyDialog.sync="customerPropertyDialog"
          :customer.sync="customer"
          v-on:closeDialog="closeDialog"
          v-on:resetAll="resetAll"
          v-on:selectCustomerProperty="selectCustomerProperty"
        ></CustomerPropertyDialog>
      </template>
    </template>
  </v-container>
</template>

<style scoped src="@/assets/sass/dashboard.scss" lang="scss"></style>
<script>
const colorPalette = ["#00D8B6", "#008FFB", "#FEB019", "#FF4560", "#775DD0"];

import { GET, QUERY } from "@/core/services/store/request.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import DCard from "@/view/pages/dashboard/DCard";
//import Calendar from "@/view/pages/dashboard/Calendar";
//import Calendar from "@/view/pages/Calendar";
import ProjectChart from "@/view/pages/dashboard/ProjectChart.vue";
export default {
  name: "dashboard",
  data() {
    return {
      projectBudgetSeries: [],
      projectBudgetCategories: [],
      projectCountList: [],
      taskCountList: [],
      ticketCountList: [],
      filtered_project: "All Project",
      filtered_project_budget: "All Project",
      filtered_ticket: "All Ticket",
      filtered_task: "All Task",
      customer: 0,
      customerPerson: 0,
      customerProperty: 0,
      customerBilling: 0,
      customerDialog: false,
      customerPersonDialog: false,
      customerPropertyDialog: false,
      transactionType: null,
      prioritySeries: [],
      priorityChartoptions: {
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          followCursor: true,
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
        },
      },
      issueSeries: [],
      issueChartoptions: {
        dataLabels: {
          enabled: false,
        },
        colors: colorPalette,
        labels: [],
        legend: {
          position: "left",
        },
      },
      issueDuration: "this_month",
      issueCountLoading: false,
      priorityDuration: "this_month",
      priorityCountLoading: false,
      countLoading: true,
      customerCountLoading: true,
      jobCountLoading: true,
      quotationCountLoading: true,
      durationList: [
        { text: "This Week", value: "this_week" },
        { text: "This Month", value: "this_month" },
        { text: "This Year", value: "this_year" },
      ],
      customerDuration: "this_month",
      jobDuration: "this_month",
      quotationDuration: "this_month",
      bugCount: 0,
      customerCount: {
        count: 0,
        all_customers: 0,
        active_customers: 0,
        percentage: 0,
      },
      quotationCount: {
        count: 0,
        percentage: 0,
        average_revenue: 0,
      },
      jobsCount: {
        count: 0,
        all_jobs: 0,
        recurring_jobs: 0,
        percentage: 0,
      },
      jobCount: {
        total_jobs: 0,
        total_open_jobs: 0,
        total_closed_jobs: 0,
        total_cancelled_jobs: 0,
      },
      visitCount: {
        total_visits: 0,
        total_open_visits: 0,
        total_overdue_visits: 0,
        total_today_visits: 0,
        total_tomorrow_visits: 0,
        total_this_week_visits: 0,
        total_upcomming_visits: 0,
        total_inprogress_visits: 0,
        total_hold_visits: 0,
        total_completed_visits: 0,
        total_cancelled_visits: 0,
      },


      lineseries: [
        {
          name: "Min. Working Hours",
          type: "column",
          data: [5, 5, 5, 5, 5, 5, 5, 5],
        },
        {
          name: "Max. Working Hours",
          type: "column",
          data: [10, 10, 10, 10, 10, 10, 10, 10],
        },
        {
          name: "Working Hours",
          type: "line",
          data: [3, 5, 12, 9, 8, 4, 6, 7],
        },
      ],
      linechartOptions: {
        chart: {
          height: 350,
          type: "line",
          stacked: false,
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          width: [1, 1, 4],
        },
        title: {
          text: "",
          align: "left",
          offsetX: 110,
        },
        xaxis: {
          categories: [
            "John",
            "Alen",
            "TM",
            "Alex",
            "David",
            "SW",
            "NS",
            "Mat",
          ],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#008FFB",
            },
            labels: {
              style: {
                colors: "#008FFB",
              },
            },
            title: {
              text: "Working Hours",
              style: {
                color: "#008FFB",
              },
            },
            tooltip: {
              enabled: true,
            },
          },
        ],
        tooltip: {
          fixed: {
            enabled: true,
            position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
            offsetY: 30,
            offsetX: 60,
          },
        },
        legend: {
          horizontalAlign: "left",
          offsetX: 40,
        },
      },

    };
  },
  methods: {
    getTicketCount() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, {
              url: "dashboard-ticket-count",
            })
            .then(({ data }) => {
              _this.ticketCountList = data.status_list;
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getProjectBudgetCount() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, {
              url: "dashboard-project-budget",
            })
            .then(({ data }) => {
              _this.projectBudgetSeries = data.series;
              _this.projectBudgetCategories = data.budgetCategories;
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getProjectListCount() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, {
              url: "dashboard-project-count",
            })
            .then(({ data }) => {
              _this.projectCountList = data.status_list;
              
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getTaskListCount() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, {
              url: "dashboard-task-count",
            })
            .then(({ data }) => {
              _this.taskCountList = data.status_list;
              
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    
    

    

    createTransaction(type) {
      this.transactionType = type;
      this.customerDialog = true;
    },
    resetAll() {
      this.customer = 0;
      this.customerPerson = 0;
      this.customerProperty = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.customerDialog = false;
      this.customerPersonDialog = false;
      this.customerPropertyDialog = false;
    },
    selectCustomer(param) {
      this.customer = param;
      this.closeDialog();
      this.checkTransactionType();
      if (!this.customerPerson || this.customerPerson <= 0) {
        this.customerPersonDialog = true;
      } else {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkTransactionType();
      if (
        (!this.customerProperty || this.customerProperty <= 0) &&
        this.transactionType != "quotation"
      ) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerProperty(param) {
      this.customerProperty = param;
      this.closeDialog();
      this.checkTransactionType();
    },
    selectCustomerBilling(param) {
      this.customerBilling = param;
      this.closeDialog();
      this.checkTransactionType();
    },
    checkTransactionType() {
      const _this = this;
      if (_this.transactionType === "quotation") {
        if (
          _this.customer > 0 &&
          _this.customerPerson > 0 &&
          _this.customerBilling > 0
        ) {
          _this.$router.push(
            _this.getDefaultRoute("quotation.create", {
              query: {
                customer: _this.customer,
                contact_person: _this.customerPerson,
                billing: _this.customerBilling,
              },
            })
          );
        }
      } else {
        if (
          _this.customer > 0 &&
          _this.customerPerson > 0 &&
          _this.customerProperty > 0
        ) {
          _this.$router.push(
            _this.getDefaultRoute(_this.transactionType + ".create", {
              query: {
                customer: _this.customer,
                contact_person: _this.customerPerson,
                property: _this.customerProperty,
              },
            })
          );
        }
      }
    },
    getDashboardCount() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, {
              url: "dashboard-count",
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardCustomerCount() {
      const _this = this;
      _this.customerCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-customer",
              data: {
                duration: _this.customerDuration,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardJobCount() {
      const _this = this;
      _this.jobCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-job",
              data: {
                duration: _this.jobDuration,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardQuotationCount() {
      const _this = this;
      _this.quotationCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-quotation",
              data: {
                duration: _this.quotationDuration,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardPriorityCount() {
      const _this = this;
      _this.priorityCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-priority-graph",
              data: {
                duration: _this.priorityDuration,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardIssueCount() {
      const _this = this;
      _this.issueCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-issue-graph",
              data: {
                duration: _this.issueDuration,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getCustomerCount() {
      const _this = this;
      _this
        .getDashboardCustomerCount()
        .then(({ customer }) => {
          if (customer) {
            _this.customerCount = customer;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.customerCountLoading = false;
        });
    },
    getJobsCount() {
      const _this = this;
      _this
        .getDashboardJobCount()
        .then(({ job }) => {
          if (job) {
            _this.jobsCount = job;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.jobCountLoading = false;
        });
    },
    getQuotationCount() {
      const _this = this;
      _this
        .getDashboardQuotationCount()
        .then(({ quotation }) => {
          if (quotation) {
            _this.quotationCount = quotation;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.quotationCountLoading = false;
        });
    },
    getPriorityGraph() {
      const _this = this;
      _this
        .getDashboardPriorityCount()
        .then(({ categories, prioritySeries }) => {
          if (categories) {
            _this.priorityChartoptions.xaxis.categories = categories;
          }
          if (prioritySeries) {
            _this.prioritySeries = prioritySeries;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.priorityCountLoading = false;
        });
    },
    getIssueGraph() {
      const _this = this;
      _this
        .getDashboardIssueCount()
        .then(({ issueSeries, labels }) => {
          if (labels) {
            _this.issueChartoptions.labels = labels;
          }
          if (issueSeries) {
            _this.issueSeries = issueSeries;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.issueCountLoading = false;
        });
    },
  },
  components: {
    CustomerDialog,
    CustomerPersonDialog,
    CustomerPropertyDialog,
    DCard,
    //Calendar,
    ProjectChart,
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
      },
    ]);
    _this.getProjectBudgetCount();
    _this.getProjectListCount();
    _this.getTaskListCount();
    _this.getTicketCount();

    _this.getCustomerCount();
    _this.getJobsCount();
    _this.getQuotationCount();
    _this.getPriorityGraph();
    _this.getIssueGraph();
    _this
      .getDashboardCount()
      .then(({ job, visit, bug }) => {
        if (job) {
          _this.jobCount = job;
        }
        if (visit) {
          _this.visitCount = visit;
        }
        if (bug) {
          _this.bugCount = bug;
        }
      })
      .catch((error) => {
        _this.logError(error);
      })
      .finally(() => {
        _this.countLoading = false;
      });
  },
};
</script>
