<template>
  <div id="chart">
    <apexchart
      type="bar"
      height="430"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>
<script>
export default {
  name: "ProjectChart",
  props: {
    seriesData: {
      type: Array,
      required: true,
      default: () => {
        return new Array();
      },
    },
    categoriesData: {
      type: Array,
      required: true,
      default: () => {
        return new Array();
      },
    },
  },
  watch: {
    seriesData: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          this.series = param;
        }
      },
    },
    categoriesData: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.length > 0) {
          this.chartOptions.xaxis.categories = param;
        }
      },
    },
  },
  data() {
    return {
      cData: [],
      sData: [],
      series: [],
      chartOptions: {
        chart: {
          type: "bar",
          height: 430,
        },
        colors: ["#3B4B97", "#93A5FF"],
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [],
        },
        legend: {
          position: "top",
          horizontalAlign: "center",
          offsetX: 40,
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#333",
            },
            labels: {
              style: {
                colors: "#333",
              },
            },
            title: {
              text: "In Thousands $",
              style: {
                color: "#333",
                fontWeight: 500,
              },
            },
          },
        ],
      },
    };
  },
};
</script>
