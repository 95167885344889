<template>
  <div
    class="d-flex rounded-lg pa-3 h-100 w-100"
    style="border: 1px solid #e5e5e5"
  >
    <div>
      <div :class="`${titleColor} rounded-circle pa-2`">
        <v-icon class="mdi-rotate-90" color="white">mdi-infinity</v-icon>
      </div>
    </div>
    <div class="w-100 px-3">
      <div class="d-flex align-items-center">
        <div :class="`fs-16 fw-500 no-wrap ${titleColor}--text`">
          {{ title }}
        </div>
        <v-spacer></v-spacer>
        <span :class="`fs-28 fw-600 ${titleColor}--text`">{{
          totalCount
        }}</span>
      </div>
      <div class="d-flex" v-if="childCount && title != 'cancelled'">
        <v-spacer></v-spacer>
        <v-avatar size="30" class="orange white--text ml-2">{{ inTimeline }}</v-avatar>
        <v-avatar size="30" class="blue darken-4 white--text ml-2">{{ overTimeline }}</v-avatar>
        <v-avatar size="30" class="blue white--text ml-2">{{ withinBudget }}</v-avatar>
        <v-avatar size="30" class="red white--text ml-2">{{ outOfBudget }}</v-avatar>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "header-card",
  data() {
    return {
      withinBudget: 0,
      outOfBudget: 0,
      inTimeline: 0, 
      overTimeline: 0

    };
  },
  props: {
    titleColor: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    totalCount: {
      type: [String, Number],
      default: 0,
    },
    childCount: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    totalCount: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          this.withinBudget = parseInt(param/2);
          this.outOfBudget = param-this.withinBudget;

          this.inTimeline = parseInt(param/3);
          this.overTimeline = param-this.inTimeline;
        }
      },
    }
  }

  
};
</script>
